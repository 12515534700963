import { useEffect } from 'react';
// import logo from './logo.svg';
import './App.css';
// import Home from './pages/Home';
import { Routes, Route, useLocation } from "react-router-dom";
// import Boutique from './pages/Boutique';
// import Nav from './components/Global/Nav';
// import Aide from './pages/Aide';
// import CreateAccount from './pages/CreateAccount';
// import MyAccount from './pages/MyAccount';
// import Dashboard from './pages/DashboardUser/Dashboard';
// import Listedesventes from './pages/DashboardUser/ListedesventesUser';
// import Listedescommendes from './pages/DashboardUser/Listedescommendes';
// import CreateVent from './pages/DashboardUser/CreateVentUser';
// import VenteDetail from './pages/DashboardUser/VenteDetail';
// import SearchPage from './pages/SearchPage';

// import DashboardAdmin from './pages/DashboardAdmin/Dashboard';
// import VentAdmin from './pages/DashboardAdmin/CreateVent';
// import CreateBoutique from './pages/DashboardAdmin/CreateBoutique';
// import ListedesventesAdmin from './pages/DashboardAdmin/Listedesventes';
// import Product from './pages/Product';
// import UpdateVent from './pages/DashboardAdmin/UpdateVent';
// import Listedesboutiques from './pages/DashboardAdmin/Listedesboutique';
// import UpdateBoutique from './pages/DashboardAdmin/UpdateBoutique.';
// import ListVendeur from './pages/DashboardAdmin/ListVendeur';
// import VendeurVoir from './pages/DashboardAdmin/VendeurVoir';
// import UpdateVendeur from './pages/DashboardAdmin/UpdateVendeur';
// import { Layout } from './components/Global/Layout';
// import ListAdmins from './pages/DashboardAdmin/ListAdmins';
// import UpdateAdmin from './pages/DashboardAdmin/UpdateAdmin';
// import NewAdmin from './pages/DashboardAdmin/NewAdmin';
// import LinkAdminVendeur from './pages/DashboardAdmin/LinkAdminVendeur';
// import ListUsers from './pages/DashboardAdmin/ListUsers';
// import CreateBoutiqueUser from './pages/DashboardUser/CreateBoutiqueUser';
// import ListedesboutiquesUser from './pages/DashboardUser/ListedesboutiqueUser';
// import UpdateBoutiqueUser from './pages/DashboardUser/UpdateBoutiqueUser.';
// import ListedesventesUser from './pages/DashboardUser/ListedesventesUser';
// import CreateVentUser from './pages/DashboardUser/CreateVentUser';
// import UpdateVentUser from './pages/DashboardUser/UpdateVentUser';
// import VendeurVoirUser from './pages/DashboardUser/VendeurVoirUser';
// import UpdateUser from './pages/DashboardUser/UpdateUser';
// import ListedesboutiquesAdmin from './pages/DashboardAdmin/ListedesboutiqueAdmin';
// import ListedesventesAdminUser from './pages/DashboardAdmin/ListedesventesAdmin';
// import UpdateVentesUsers from './pages/DashboardAdmin/UpdateVentesUsers';
// import ForgetPassword from './pages/ForgetPassword';
// import UpdatePassword from './pages/UpdatePassword';
// import ProtectedRoute from './pages/ProtectedRoute';
// import ListeCat from './pages/DashboardAdmin/ListCat';
// import CreateCat from './pages/DashboardAdmin/CreateCat';
// import UpdateCat from './pages/DashboardAdmin/UpdateCat';
// import Simulation from './pages/Simulation';
// import CreateQrCode from './pages/DashboardUser/CreateQrCode';
// import SimulationAchats from './pages/DashboardUser/Simulation';
// import FormRegister from './components/CreateAccount/FormRegister';
// import Inscription from './pages/DashboardAdmin/Inscription';
// import InscriptionByid from './pages/DashboardAdmin/InscriptionById';
// import MakePdf from './components/MakePdf';
// import IncubationRequests from './pages/DashboardAdmin/IncubationRequests';
// import IncubationRequestsByid from './pages/DashboardAdmin/IncubationRequestsById';
// import ConsultingRequests from './pages/DashboardAdmin/ConsultingRequests';
// import ConsultingRequestsByid from './pages/DashboardAdmin/ConsultingRequestsByid';
// import TranslateRequests from './pages/DashboardAdmin/TranslateRequests';
// import TranslateRequestsByid from './pages/DashboardAdmin/TranslateRequestsById';
// import SocialMediaRequests from './pages/DashboardAdmin/SocialMediaRequests';
// import SocialMediaRequestsByid from './pages/DashboardAdmin/SocialMediaRequestsByid';
// import ContactRequests from './pages/DashboardAdmin/ContactRequests';
// import ContactRequestsByid from './pages/DashboardAdmin/ContactRequestsById';
// import RateRequests from './pages/DashboardAdmin/RateRequests';
// import RateRequestsByid from './pages/DashboardAdmin/RateRequestsById';
// import UpdateAdminPage from './pages/DashboardAdmin/UpdateAdminPage';
// import PaymentDossier from './pages/DashboardAdmin/PaymentDossier';
// import UpdatePaymentDossier from './pages/DashboardAdmin/UpdatePaymentDossier';
// import ProjectsCategories from './pages/DashboardAdmin/ProjectsCategories';
// import UpdateProjectCategorie from './pages/DashboardAdmin/UpdateProjectsCategories';
// import CreateProject from './pages/DashboardAdmin/CreateProject';
// import ProjectsList from './pages/DashboardAdmin/ProjectsList';
// import UpdateProject from './pages/DashboardAdmin/UpdateProject';
// import UpdateClientDocuments from './pages/DashboardAdmin/UpdateUserDocuments';
// import AddAction from './pages/DashboardAdmin/AddAction';
// import ClientActions from './pages/DashboardAdmin/ClientsActions';
// import SimulationIamal from './pages/DashboardAdmin/Simulation';
// import PDF from './pages/PDF';
// import GalleryProjectsList from './pages/DashboardAdmin/GalleryProjectsList';
// import CreateGalleryProject from './pages/DashboardAdmin/CreateGalleryProject';
// import UpdateGalleryProject from './pages/DashboardAdmin/UpdateGalleryProject';
import ProtectedRouteAlsalam from './pages/ProtectedRouteAlsalam';
import LoginAlsalam from './pages/LoginAlsalam';
import DashboardAlsalam from './pages/DashboardAlsalam/Dashboard';
import ListDemandes from './pages/DashboardAlsalam/ListDemandes';
// import VoirDemande from './pages/DashboardAlsalam/VoirDemande';
import ClientDocuments from './pages/DashboardAlsalam/ClientDocuments';
import EtatProjet from './pages/DashboardAlsalam/EtatProjet';
// import ScanDocuments from './pages/DashboardAdmin/ScanDocuments';
import ScanedDocuments from './pages/DashboardAlsalam/ScanedDocuments';
// import FormationsList from './pages/DashboardAdmin/FormationsList';
// import CreateFormation from './pages/DashboardAdmin/CreateFormation';
// import UpdateFormation from './pages/DashboardAdmin/UpdateFormation';
// import PaymentFormation from './pages/DashboardAdmin/PaymentFormation';
// import UpdatePaymentFormation from './pages/DashboardAdmin/UpdatePaymentFormation';
// import EquipmentsList from './pages/DashboardAdmin/EquipmentsList';
// import CreateEquipment from './pages/DashboardAdmin/CreateEquipment';
// import UpdateEquipment from './pages/DashboardAdmin/UpdateEquipment';
// import ProjectsEquipmentsLinks from './pages/DashboardAdmin/ProjectsEquipmentsLinks';
// import WorkshopsList from './pages/DashboardAdmin/WorkshopsList';
// import CreateWorkshop from './pages/DashboardAdmin/CreateWorkshop';
// import UpdateWorkshop from './pages/DashboardAdmin/UpdateWorkshop';
// import WorkshopRequests from './pages/DashboardAdmin/WorkshopRequests';
// import WorkshopRequestsByid from './pages/DashboardAdmin/WorkshopRequestsById';
// import SimulationIamalNormal from './pages/DashboardAdmin/SimulationNormal';
// import ListDemandesAdmin from './pages/DashboardAdmin/ListDemandes';
// import RelationClient from './pages/DashboardAdmin/RelationClient';
// import Logistique from './pages/DashboardAdmin/Logistique';
import VoirClient from './pages/DashboardAlsalam/VoirClient';




function App() {

  // const location = useLocation();

  // console.log(location);


  // useEffect(() => {

  //   var nav = document.querySelector('nav'); // Identify target

  //   if (nav) {

  //     window.addEventListener('scroll', function (event) { // To listen for event
  //       event.preventDefault();

  //       if (window.scrollY <= 150) { // Just an example
  //         nav.style.backgroundColor = 'transparent';
  //       } else {
  //         nav.style.backgroundColor = '#3838388c';
  //       }
  //     });


  //   }

  // }, [location])

  // console.log(process.env.REACT_APP_LOCAL_URL);


  return (
    <>

      {/* {location.pathname !== "/account" && location.pathname !== "/dashboard" && location.pathname !== "/listventes" && location.pathname !== "/listcommendes" && location.pathname !== "/createvent" && location.pathname !== "/listventesadmin" && location.pathname !== "/vente" ? <Nav /> : ''} */}

      <Routes>

        <Route path="*" element={<LoginAlsalam />} />
        <Route path="/" element={<LoginAlsalam />} />

        {/* <Route path="/store-form" element={<FormRegister />} />
      <Route path="/makepdf" element={<MakePdf />} /> */}


        {/* <Route path="/" element={<Layout />}>
           
          <Route index element={<Home />} />
          <Route path="/boutique/:slug" element={<Boutique />} />
         
          <Route path="/aide" element={<Aide />} />
          
          <Route path="/createaccount" element={<CreateAccount />} />
          <Route path="/search" element={<SearchPage />} />
        <Route path="/product/:boutique/:vente" element={<Product />} />

        </Route> */}


        {/* <Route path="/account" element={<MyAccount />} />
        <Route path='/forgetpassword' element={< ForgetPassword />} />
        <Route path='/updatepassword' element={< UpdatePassword />} /> */}


        {/* <Route path="/login-alsalam" element={<LoginAlsalam />} /> */}
        <Route element={<ProtectedRouteAlsalam Role={"adminBoutique"} />} >

          {/* Dashborad Alsalam */}
          <Route path="/dashboard" element={<DashboardAlsalam />} />
          <Route path="/list-demandes" element={<ListDemandes />} />
          {/* <Route path="/voir-demande/:id" element={<VoirDemande />} /> */}
          <Route path="/client-documents/:id" element={<ClientDocuments />} />
          <Route path="/scanned-documents/:id" element={<ScanedDocuments />} />
          <Route path="/voir-demande/:id" element={<VoirClient />} />


          <Route path="/status-project/:id" element={<EtatProjet />} />



          {/* Dashborad Alsalam */}

        </Route>


        {/* <Route element={<ProtectedRoute Role={"adminBoutique"} />} > */}

          {/* Dashborad User */}
          {/* <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/createboutiqueuser" element={<CreateBoutiqueUser />} />
          <Route path="/listboutiquesuser" element={<ListedesboutiquesUser />} />
          <Route path="/updateboutiqueuser/:id_btq" element={<UpdateBoutiqueUser />} />

          <Route path="/createvent" element={<CreateVentUser />} />
          <Route path="/createqrcode" element={<CreateQrCode />} />
          <Route path="/simulation" element={<SimulationAchats />} />


          <Route path="/listventes" element={<ListedesventesUser />} />
          <Route path="/updatevent/:id_btq/:id_vente" element={<UpdateVentUser />} />

          <Route path="/vendeur" element={<VendeurVoirUser />} />

          <Route path="/updateuser" element={<UpdateUser />} />

          <Route path="/vente" element={<VenteDetail />} />
          <Route path="/listcommendes" element={<Listedescommendes />} /> */}
          {/* Dashborad User */}

        {/* </Route> */}





        {/* <Route element={<ProtectedRoute Role={"adminDZ"} />} > */}
          {/* Dashborad Admin */}
          {/* <Route path="/dashboardadmin" element={<DashboardAdmin />} />

          <Route path="/createventadmin" element={<VentAdmin />} />
          <Route path="/updateventadmin/:id_btq/:id_vente" element={<UpdateVent />} />
          <Route path="/updateventadminusers/:id_btq/:id_vente" element={<UpdateVentesUsers />} />


          <Route path="/createboutique" element={<CreateBoutique />} />
          <Route path="/listboutiquesadmin" element={<ListedesboutiquesAdmin />} />
          <Route path="/updateboutiqueadmin/:id_btq" element={<UpdateBoutique />} />

          <Route path="/listventesadmin" element={<ListedesventesAdmin />} />
          <Route path="/listventesuseradmin" element={<ListedesventesAdminUser />} />



          <Route path="/listadmins" element={<ListAdmins />} />
          <Route path="/newadmin" element={< NewAdmin />} />
          <Route path="/linkadminvendeur/:id" element={< LinkAdminVendeur />} />
          <Route path="/updateadmin/:id" element={<UpdateAdmin />} />
          <Route path="/updateadminpage/:id" element={<UpdateAdminPage />} />


          <Route path="/listusers" element={<ListUsers />} />


          <Route path="/inscription" element={<Inscription />} />
          <Route path="/inscriptionbyid/:id" element={<InscriptionByid />} />


          <Route path="/incubation-requests" element={<IncubationRequests />} />
          <Route path="/incubation-requests/:id" element={<IncubationRequestsByid />} />


          <Route path="/consulting-requests" element={<ConsultingRequests />} />
          <Route path="/consulting-requests/:id" element={<ConsultingRequestsByid />} />

          <Route path="/translate-requests" element={<TranslateRequests />} />
          <Route path="/translate-requests/:id" element={<TranslateRequestsByid />} />

          <Route path="/social-media-requests" element={<SocialMediaRequests />} />
          <Route path="/social-media-requests/:id" element={<SocialMediaRequestsByid />} />


          <Route path="/contact-requests" element={<ContactRequests />} />
          <Route path="/contact-requests/:id" element={<ContactRequestsByid />} />

          <Route path="/rate-requests" element={<RateRequests />} />
          <Route path="/rate-requests/:id" element={<RateRequestsByid />} />


          <Route path="/listvendeuradmin" element={<ListVendeur />} />
          <Route path="/listdemandesadmin" element={<ListDemandesAdmin />} />
          <Route path="/relationclient" element={<RelationClient />} />
          <Route path="/logistique" element={<Logistique />} />



          <Route path="/vendeurbyone/:id" element={<VendeurVoir />} />
          <Route path="/updatevendeur/:id" element={<UpdateVendeur />} />



          <Route path="/listboutiques" element={<Listedesboutiques />} />


          <Route path="/category" element={<ListeCat />} />
          <Route path="/updatecategory/:id" element={<UpdateCat />} />


          <Route path="/paymentdossier" element={<PaymentDossier />} />
          <Route path="/updatepaymentdossier/:id" element={<UpdatePaymentDossier />} />

          <Route path="/paymentformation" element={<PaymentFormation />} />
          <Route path="/updatepaymentformation/:id" element={<UpdatePaymentFormation />} />



          <Route path="/projectscategories" element={<ProjectsCategories />} />
          <Route path="/createcategory" element={<CreateCat />} />
          <Route path="/updateprojectcategory/:id" element={<UpdateProjectCategorie />} />

          <Route path="/projects" element={<ProjectsList />} />
          <Route path="/createproject" element={<CreateProject />} />
          <Route path="/updateproject/:id_vente" element={<UpdateProject />} />

          <Route path="/projectsequipment" element={<EquipmentsList />} />
          <Route path="/createequipment" element={<CreateEquipment />} />
          <Route path="/updateequipment/:id_vente" element={<UpdateEquipment />} />

          <Route path="/projects_equipments_links/:id_vente" element={<ProjectsEquipmentsLinks />} />


          <Route path="/formations" element={<FormationsList />} />
          <Route path="/createformation" element={<CreateFormation />} />
          <Route path="/updateformation/:id_vente" element={<UpdateFormation />} />

          <Route path="/workshops" element={<WorkshopsList />} />
          <Route path="/createworkshop" element={<CreateWorkshop />} />
          <Route path="/updateworkshop/:id_vente" element={<UpdateWorkshop />} />

          <Route path="/workshops-requests" element={<WorkshopRequests />} />
          <Route path="/workshop-requests/:id" element={<WorkshopRequestsByid />} />








          <Route path="/updatedocuments/:id" element={<UpdateClientDocuments />} />
          <Route path="/scandocuments/:id" element={<ScanDocuments />} />
          <Route path="/addaction/:id" element={<AddAction />} />
          <Route path="/clientaction/:id" element={<ClientActions />} />

          <Route path="/simulationiamal" element={<SimulationIamal />} />
          <Route path="/simulationiamalnormal" element={<SimulationIamalNormal />} />



          <Route path="/galleryprojects" element={<GalleryProjectsList />} />
          <Route path="/creategalleryproject" element={<CreateGalleryProject />} />
          <Route path="/updategalleryproject/:id_vente" element={<UpdateGalleryProject />} /> */}














          {/* <Route path="/vente" element={<VenteDetail />} />
    <Route path="/listcommendes" element={<Listedescommendes />} /> */}
          {/* Dashborad Admin */}
        {/* </Route> */}


        {/* <Route path="/pdftest" element={<PDF />} /> */}




      </Routes>
    </>
  );
}

export default App;
