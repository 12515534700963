import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardAlsalam/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import LoadData from '../../components/Global/LoadData';
import GoBack from '../../components/Global/GoBack';
import LangContext from '../../Context/lang';

const VoirClient = () => {

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? searchParams.get('page') : '1';
    const type = searchParams.get('type') ? searchParams.get('type') : '0';


    let { id } = useParams();

    const { Lang, setLang, Panier, Global, validation, dartaksitsim, daridjarPage, BanqueDossier } = useContext(LangContext);



    const [ListVentes, setListVentes] = useState([])

    const [PageNumber, setPageNumber] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(parseInt(page))

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.post(`https://diardzair.com.dz/api/taksit/rest/get/creditDemandes`,
                {"username":"test_alsalam_opr","orderId": Auth?.token || '' , "hash":id || '' , "tag":"" },
                {
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                    }
                  }

                //  {
                //     headers: {
                //         'Authorization': `Bearer ${Auth?.token}`
                //     }
                // }
                );
                if (response['errorMessage'] == 'session is incorrect') {
                    setAuth(false);
                    return navigate('/')

                }
                console.log(response);
                setListVentes(response);
                // setPageNumber(response['AllNumberOfPage'])
                // setCurrentPage(response['currentPage'])

                // if (parseInt(page) !== response['currentPage']) {
                //     setCurrentPage(1)
                //     console.log("hhhhhhhhhhh");
                // }
                // console.log(DarTaksitCat)

            } catch (error) {
                setLoading(false);

                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    console.log(ListVentes);



    useEffect(() => {


        // if (Auth) {
        //   console.log('hi!');

        // }else{
        //   console.log('no!'); 
        //   return navigate("/");
        // }


    }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const paymenttype = ['CIB','Edahabia','CCP','Espèces'];
    // const paymenttype = [{ id: 0, name: 'CIB' }, { id: 1, name: 'Edahabia' }, { id: 2, name: 'CCP' }, { id: 3, name: 'Espèces' }]

    function convertStringToDate(dateString) {
        // Extract year, month, and day from the string
        const year = parseInt(dateString?.substring(0, 2));
        const month = parseInt(dateString?.substring(2, 4)) - 1; // Months are zero-based in JavaScript
        const day = parseInt(dateString?.substring(4, 6));
    
        // Create a new Date object
        const date = new Date(2000 + year, month, day); // Assuming the year range is 2000-2099
    
        return date?.toLocaleDateString('fr');
    }


    return (
        <div className='dashboard'>


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'> Demande Information<span>BEA</span></div>
                    {/* <GoBack  url={type == 0 ? "/listvendeuradmin" : "/fournisseurs"} /> */}

                    {Loading ? <LoadData /> : ''}

                    {!Loading && ListVentes?.length != 0 ?

                        <>
                            <div className="conatiner mt-4 mb-5">
                            {/* <div className='new-admin-btn mb-0' onClick={() => navigate('/addaction/'+id)}> Nouvelle action  </div>
                            <div className='new-admin-btn mt-2 mb-4' onClick={() => navigate('/clientaction/'+id)}> Voir toutes les actions  </div> */}
                             <div className="conatiner mb-5">
                                    <ul className="list-group list-store-admin">
                                        <li className={`list-group-item active gradiant`} aria-current="true">Panier 
                                       
                                            
                                            </li>

                                            <div className='container info-account cart mt-4'>
                                    {
                      ListVentes?.panier?.length > 0 && ListVentes?.panier?.map((item, i) => (

                        // <ProductCart item={item} key={item.hash} />

                        <div key={`SimulationForm${item?.hashAnnonce}`} className='d-flex flew-wrap align-items-center mb-2 mt-2 cart-product-section'>

                          <div className='me-3'>
                            <div className='product-item-img'>
                              {/* <Link to={`/product/${item?.hashAnnonce}`}> */}
                                <img className="cart-img" src={`https://opr.diardzair.com.dz/${item?.image}`} />
                              {/* </Link> */}
                            </div>
                          </div>
                          <div className=''>
                            {/* <Link to={`/product/${item?.hashAnnonce}`}> */}
                              <p className='cart-product-name'>{item?.nom}</p>
                              <p className='cart-product-marque mb-0 pb-0'>Marque : {item?.marque}</p>
                              <p className='cart-product-marque mb-0 pb-0'>Quantité : {item?.qte}</p>
                            <p className='cart-product-marque mb-0 pb-0'>Prix : {item?.montant} DA</p>
                            {/* </Link> */}
                          </div>

                          <div className='col-lg-4 col-md-6 col-sm-5 text-center mt-3 mt-md-0'>
                          </div>


                        </div>

                      
                      ))
                    }
                                           <div className='mb-4 mt-3'>  <b>  Total : {ListVentes?.demande?.mt_produit} DA</b></div> 
                    
                    </div>
                                      
                                       
                                    </ul>
                                </div> 
 
                                <ul className="list-group list-store-admin mt-2">
                                    <li className={`list-group-item active gradiant`} aria-current="true">Information Generale</li>
                                    {/* {ListVentes?.demande?.numero_demande ? <li className="list-group-item"> <b>Id</b> : {ListVentes?.demande?.numero_demande}</li> : ''}
                                    {ListVentes?.demande?.nom || ListVentes?.demande?.prenom ? <li className="list-group-item"> <b>Nom et Prénom</b> : {ListVentes?.demande?.nom} {ListVentes?.demande?.prenom}</li> : ''}
                                    {ListVentes?.demande?.civilite ? <li className="list-group-item"><b> Civilité</b> : {ListVentes?.demande?.civilite}</li> : ''}
                                    {ListVentes?.mobile ? <li className="list-group-item"><b> Mobile </b> : {ListVentes?.mobile}</li> : ''}
                                    <li className="list-group-item"><b>Mobile Validé </b> : {ListVentes?.mobile_valid == 1 ? 'Oui' : "Non"}</li>
                                    <li className="list-group-item"><b>Abonnement -  Payment Validé </b> : {ListVentes?.payment_valid == 1 ? 'Oui' : "Non"}</li>
                                    {ListVentes?.payment_methode != null ? <li className="list-group-item"><b>Abonnement - Type de paiement </b> : {paymenttype[ListVentes?.payment_methode]}</li> : ""}
                                    <li className="list-group-item"><b>Dossier -  Payment Validé </b> : {ListVentes?.payment_dossier_valid == 1 ? 'Oui' : "Non"}</li>
                                    {ListVentes?.payment_dossier_methode != null ? <li className="list-group-item"><b>Dossier - Type de paiement </b> : {paymenttype[ListVentes?.payment_dossier_methode]}</li> : ""} */}
                                   

                                    {ListVentes?.demande?.numero_demande && <li className="list-group-item"><b>{(validation['Numero'][Lang])}</b> : {ListVentes?.demande?.numero_demande}</li>}
                  {ListVentes?.demande?.date_demande && <li className="list-group-item"><b>{(validation['date'][Lang])}</b> : {convertStringToDate(ListVentes?.demande?.date_demande || '') }</li>}
                  {ListVentes?.demande?.duree && <li className="list-group-item"><b>{(validation['durée'][Lang])}</b> : {ListVentes?.demande?.duree} {(dartaksitsim['Mois'][Lang])}</li>}
                  {ListVentes?.demande?.mtEch && <li className="list-group-item"><b>{(validation['mensualité'][Lang])} </b>: {ListVentes?.demande?.mtEch} {(Global['Dajust'][Lang])}</li>}
                  {ListVentes?.demande?.mtRmb && <li className="list-group-item"><b>{(validation['total_rem'][Lang])}</b> : {ListVentes?.demande?.mtRmb} {(Global['Dajust'][Lang])}</li>}
                  {ListVentes?.demande?.civilite && <li className="list-group-item"><b>{(validation['civilité'][Lang])}</b> : {ListVentes?.demande?.civilite}</li>}
                  {ListVentes?.demande?.nom && <li className="list-group-item"><b>{(validation['Nom'][Lang])}</b> : {ListVentes?.demande?.nom}</li>}
                  {ListVentes?.demande?.prenom && <li className="list-group-item"><b>{(validation['Prénom'][Lang])}</b> : {ListVentes?.demande?.prenom}</li>}
                  {ListVentes?.demande?.prenomPere && <li className="list-group-item"><b>{(validation['Prénompère'][Lang])}</b> :{ListVentes?.demande?.prenomPere}</li>}
                  {ListVentes?.demande?.nomMere && <li className="list-group-item"><b>{(validation['Nommère'][Lang])} </b>: {ListVentes?.demande?.nomMere}</li>}
                  {ListVentes?.demande?.prenomMere && <li className="list-group-item"><b>{(validation['Prenommère'][Lang])}</b> : {ListVentes?.demande?.prenomMere}</li>}
                  {ListVentes?.demande?.dateNaissance && <li className="list-group-item"><b>{(validation['Date_naissance'][Lang])}</b> :  {ListVentes?.demande?.dateNaissance}</li>}
                  {ListVentes?.demande?.wilayaNaissance && <li className="list-group-item"><b>{(validation['Wilaya_naissance'][Lang])}</b> : {ListVentes?.demande?.wilayaNaissance}</li>}
                  {ListVentes?.demande?.commune_naissance && <li className="list-group-item"><b>{(validation['Commune_naissance'][Lang])}</b> :{ListVentes?.demande?.commune_naissance}</li>}
                  {ListVentes?.demande?.numActNaissance && <li className="list-group-item"><b>{(validation['Num_act_naissance'][Lang])}</b> : {ListVentes?.demande?.numActNaissance}</li>}
                  {ListVentes?.demande?.stFamiliale && <li className="list-group-item"><b>{(validation['situation_familiale'][Lang])}</b> :  {ListVentes?.demande?.stFamiliale == "c" && 'Célibataire'} {ListVentes?.demande?.stFamiliale == 'm' && 'Marié'} {ListVentes?.demande?.stFamiliale == 'd' && 'Divorcé'} {ListVentes?.demande?.stFamiliale == 'v' && 'veuf'}</li>}
                  {ListVentes?.demande?.niveau && <li className="list-group-item"><b>{(validation['Niveau'][Lang])}</b> : {ListVentes?.demande?.niveau == "1" && 'Moins que le niveau secondaire'} {ListVentes?.demande?.niveau == '2' && 'Niveau secondaire'} {ListVentes?.demande?.niveau == '3' && 'Bachelier'} {ListVentes?.demande?.niveau == '4' && 'Universitaire'}</li>}
                  {ListVentes?.demande?.nbEnfants >= 0 ? <li className="list-group-item"><b>{(validation['nbrEnfants'][Lang])}</b> : {ListVentes?.demande?.nbEnfants}</li> : ''}
                  {ListVentes?.demande?.nbAutre >= 0 ? <li className="list-group-item"><b>{(validation['nbrpersonnes'][Lang])}</b> :  {ListVentes?.demande?.nbAutre}</li> : ''}

                  {ListVentes?.demande?.wilaya && <li className="list-group-item"><b>{(validation['Wilaya'][Lang])}</b> :   {ListVentes?.demande?.wilaya}</li>}
                  {ListVentes?.demande?.dairas && <li className="list-group-item"><b>{(validation['Daïras'][Lang])}</b> :  {ListVentes?.demande?.dairas}</li>}
                  {ListVentes?.demande?.commune && <li className="list-group-item"><b>{(validation['Commune'][Lang])}</b> :  {ListVentes?.demande?.commune}</li>}
                  {ListVentes?.demande?.codePostal && <li className="list-group-item"><b>{(validation['Codepostal'][Lang])}</b> :  {ListVentes?.demande?.codePostal}</li>}
                  {ListVentes?.demande?.adresse && <li className="list-group-item"><b>{(validation['Adresse'][Lang])}</b> :  {ListVentes?.demande?.adresse}</li>}
                  {ListVentes?.demande?.mobile && <li className="list-group-item"><b>{(validation['Mobile'][Lang])}</b> :  {ListVentes?.demande?.mobile}</li>}
                  {ListVentes?.demande?.telephone_professionnel && <li className="list-group-item"><b>{(validation['Mobile2'][Lang])}</b> : {ListVentes?.demande?.telephone_professionnel}</li>}
                  {ListVentes?.demande?.email && <li className="list-group-item"><b>{(validation['email'][Lang])}</b> :  {ListVentes?.demande?.email}</li>}
                  {ListVentes?.demande?.piece && <li className="list-group-item"><b>{(validation['piece'][Lang])}</b> :  {ListVentes?.demande?.piece}</li>}
                  {ListVentes?.demande?.nin && <li className="list-group-item"><b>{(validation['NIN'][Lang])}</b> : {ListVentes?.demande?.nin}</li>}
                  {ListVentes?.demande?.pieceNum && <li className="list-group-item"><b>{(validation['numcn'][Lang])}</b> :  {ListVentes?.demande?.pieceNum}</li>}
                  {ListVentes?.demande?.pieceDate && <li className="list-group-item"><b>{(validation['Date_délivrence'][Lang])}</b> :   {ListVentes?.demande?.pieceDate}</li>}
                  {ListVentes?.demande?.pieceParW && <li className="list-group-item"><b>{(validation['Wilaya_délivrence'][Lang])}</b> :  {ListVentes?.demande?.pieceParW}</li>}
                  {ListVentes?.demande?.piecePar && <li className="list-group-item"><b>{(validation['Commune_délivrence'][Lang])}</b> :  {ListVentes?.demande?.piecePar}</li>}
                  {ListVentes?.demande?.habitation && <li className="list-group-item"><b>{(validation['Habitation'][Lang])}</b> :  {ListVentes?.demande?.habitation == "1" && 'Avec les parents'} {ListVentes?.demande?.habitation == '2' && 'Locataire'} {ListVentes?.demande?.habitation == '3' && 'Propriétaire'} {ListVentes?.demande?.habitation == '4' && 'Logt de Fonction'}</li>}



                  
                  {ListVentes?.demande?.stActivite && <li className="list-group-item"><b>{(validation['secteuractiv'][Lang])}</b> : {ListVentes?.demande?.stActivite == 'i' && 'Indépendant'} {ListVentes?.demande?.stActivite == 'p' && 'Public'} {ListVentes?.demande?.stActivite == 'v' && 'Privé'} {ListVentes?.demande?.stActivite == 'r' && 'Retraité'}</li>}
                  {ListVentes?.demande?.profession && <li className="list-group-item"><b>{(validation['Profession'][Lang])}</b> : {ListVentes?.demande?.profession} </li>}
                  {ListVentes?.demande?.typeContrat != 0 && <li className="list-group-item"><b>{(validation['TypeDeContrat'][Lang])}</b> : {ListVentes?.demande?.typeContrat} </li>}


                 

              
                  {ListVentes?.demande?.salaire && <li className="list-group-item"><b>{(validation['Salaire'][Lang])}</b> :  {ListVentes?.demande?.salaire} {(Global['Da'][Lang])}</li>}
                  {ListVentes?.demande?.compte && <li className="list-group-item"><b>{(validation['Compte'][Lang])}</b> : {ListVentes?.demande?.compte} </li>}

                  {
                    ListVentes?.demande?.compte == "ccp" ? 
                    <>
                   { ListVentes?.demande?.cptCcp && <li className="list-group-item"><b>Numéro de compte CCP</b> : {ListVentes?.demande?.cptCcp} </li>}
                   { ListVentes?.demande?.cleCcp && <li className="list-group-item"><b>Clé CCP</b> : {ListVentes?.demande?.cleCcp} </li>}
                    </>
                    : ''


                  }
                  {ListVentes?.demande?.banque != 0 && <li className="list-group-item"><b>{(validation['bank'][Lang])}</b> :{ListVentes?.demande?.banque} </li>}
                  {ListVentes?.demande?.autresRevenus && <li className="list-group-item"><b>Autre Sources De Revenus </b>: {ListVentes?.demande?.autresRevenus}</li>}
               

                
                  {ListVentes?.demande?.creditImmobilier ? <li className="list-group-item"><b>Crédit Emprunt Immobilier </b>:  {ListVentes?.demande?.creditImmobilier}</li> : ''}
                  {ListVentes?.demande?.creditVoitur ? <li className="list-group-item"><b>Crédit Voiture </b>: {ListVentes?.demande?.creditVoitur}</li> : ''}
                  {ListVentes?.demande?.creditAutre ? <li className="list-group-item"><b>Crédit Autre </b>: {ListVentes?.demande?.creditAutre}</li> : ''}
                  {ListVentes?.demande?.rloyer ? <li className="list-group-item"><b>Un Loyer À Payer En Cours </b>: {ListVentes?.demande?.rloyer}</li> : ''}
               
              
                  {ListVentes?.demande?.compteEpargne ? <li className="list-group-item"><b>{(validation['charges'][Lang])} </b>:  {ListVentes?.demande?.compteEpargne}</li> : ''}
                  {ListVentes?.demande?.biens ? <li className="list-group-item"><b>Biens Immeubles Ou Meubles  </b>:  {ListVentes?.demande?.biens}</li> : ''}
              


                                    {/* {ListVentes?.wilaya_naissance ? <li className="list-group-item"><b> Wilaya de Naissence </b>: {ListVentes?.wilaya_naissance}</li> : ''}
                            {ListVentes?.commune_naissance ? <li className="list-group-item"><b> Commune de Naissence </b>: {ListVentes?.commune_naissance}</li> : ''}
                            {ListVentes?.wilaya ? <li className="list-group-item"><b> Wilaya </b>: {ListVentes?.wilaya}</li> : ''}
                            {ListVentes?.dairas ? <li className="list-group-item"><b> Daira </b>: {ListVentes?.dairas}</li> : ''}
                            {ListVentes?.commune ? <li className="list-group-item"><b> Commune </b>: {ListVentes?.commune}</li> : ''}
                            {ListVentes?.code_postal ? <li className="list-group-item"><b> Code Postal </b>: {ListVentes?.code_postal}</li> : ''}
                            {ListVentes?.adresse ? <li className="list-group-item"><b> Adresse </b>: {ListVentes?.adresse}</li> : ''}
                            {ListVentes?.nin ? <li className="list-group-item"><b> Numéro d'immatriculation au registre du commerce </b>: {ListVentes?.nin}</li> : ''}
                            {ListVentes?.nrc ? <li className="list-group-item"><b> Centre national du registre de commerce NRC </b>: {ListVentes?.nrc}</li> : ''}
                            {ListVentes?.nif ? <li className="list-group-item"><b> Numéro d'identification fiscale (nif) </b>: {ListVentes?.nif}</li> : ''}
                            {ListVentes?.article_imposition ? <li className="list-group-item"><b> Article d'imposition </b>: {ListVentes?.article_imposition}</li> : ''}
                            {ListVentes?.tva ? <li className="list-group-item"><b> ETES-vous assujetti à la tva ? </b>: {ListVentes?.tva}</li> : ''}
                            {ListVentes?.type_vendeur ? <li className="list-group-item"><b> vous êtes une personne physique ou une entreprise ? </b>: {ListVentes?.type_vendeur}</li> : ''}
                            {ListVentes?.tel ? <li className="list-group-item"><b> Tel </b>: {ListVentes?.tel}</li> : ''}
                            {ListVentes?.secteur_activite ? <li className="list-group-item"><b> Secteur d'activité </b>: {ListVentes?.secteur_activite}</li> : ''}
                            {ListVentes?.nom_boutique ? <li className="list-group-item"><b> Nom de la boutique </b>: {ListVentes?.nom_boutique}</li> : ''}
                            {ListVentes?.type_piece_idnt ? <li className="list-group-item"><b> Type de piéce d'identité </b>: {ListVentes?.type_piece_idnt}</li> : ''}
                            {ListVentes?.piece_idnt ? <li className="list-group-item"><b>Piéce d'identité </b>: <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${ListVentes?.piece_idnt}`}> Voir </a>  </li> : ''}
                            {ListVentes?.rc_file ? <li className="list-group-item"><b>Copie de l'extrait du registre du commerce </b>: <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${ListVentes?.rc_file}`}> Voir </a>  </li> : ''}
                            {ListVentes?.nif_file ? <li className="list-group-item"><b>l'attestation d'immatriculation du NIF </b>: <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${ListVentes?.nif_file}`}> Voir </a>  </li> : ''} */}
                                </ul>
                            </div>
                           
                                <div className="conatiner mb-5">
                                    <ul className="list-group list-store-admin">
                                        <li className={`list-group-item active gradiant`} aria-current="true">Documents 
                                        {/* <a href={`/updatedocuments/${id}`} className='btn-dossier'> <div className='mx-1 d-flex'><div>Modifier</div>
                                                <img className='ms-2' src="https://cdn-icons-png.flaticon.com/512/6747/6747196.png" width={"30"} />

                                            </div></a> */}
                                            
                                            </li>
                                        {/* <li className="list-group-item"><b>Carte d'identité </b> : {ListVentes?.carte_identite ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.carte_identite}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Acte de naissance </b> : {ListVentes?.acte_de_naissance ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.acte_de_naissance}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Justificatif de résidence  </b> : {ListVentes?.fiche_residence ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.fiche_residence}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Fiche Familiale </b> : {ListVentes?.fiche_familiale ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.fiche_familiale}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Extrait de Compte </b> : {ListVentes?.extrait_de_comptes ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.extrait_de_comptes}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Attestation de travail </b> : {ListVentes?.attestation_de_travail ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.attestation_de_travail}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Photo</b> : {ListVentes?.photo ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.photo}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Une copie d’échéancier </b> : {ListVentes?.echeancier ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.echeancier}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Chèque barré</b> : {ListVentes?.cheque_barre ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.cheque_barre}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Fiches de Paie 1</b> : {ListVentes?.fiches_de_paie ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.fiches_de_paie}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Fiches de Paie 2</b> : {ListVentes?.fiches_de_paie_2 ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.fiches_de_paie_2}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                                        <li className="list-group-item"><b>Fiches de Paie 3</b> : {ListVentes?.fiches_de_paie_3 ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.fiches_de_paie_3}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li> */}

                                        {
                      ListVentes?.files?.length > 0 && ListVentes?.files?.map((item, i) => (

                        <li className="list-group-item"><b> {item?.nom}</b> : {ListVentes?.carte_identite ? <a className='vendeur-dossier-voir' style={{ color: "black" }} target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/pdf?test=${ListVentes?.carte_identite}`}> Voir </a> : "Le fichier n'est pas téléchargé"}</li>
                        

                      
                      ))
                    }
                                       
                                    </ul>
                                </div> 

                           
                        </>
                        : ""}
                </div>
            </div>


        </div>
    )
}

export default VoirClient