import {useState} from 'react'
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

function GoBack({url}) {

    let navigate = useNavigate();

    return (
        <div className='container'><div onClick={()=> navigate(url)} className='dashboard-back'>{'<<'} Retour </div></div>
    )
}

export default GoBack